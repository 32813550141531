import React from "react";
import { graphql, Link } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const NavCon = styled.nav`
  display: inline-block;
  vertical-align: top;
  z-index: 2000;
  /* background-color: yellow; */
  width: auto;

  position: sticky;
  top: 10px;
  left: 10px;
  @media (max-width: 850px) {
    position: relative;
    top: 0px;
    left: 0px;
  }
`;
const LiNavList = styled.li`
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.5px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  opacity: 0.6;
  font-family: "Unica77LLWeb-Bold", helvetica;
  &:hover {
    opacity: 1;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
  }
  @media (min-width: 1024px) and (orientation: portrait) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

const AboutPageCon = styled.div`
  width: calc(100% - 20px);
  margin: 10px;
`;

const AboutTextCon = styled.div`
  margin-left: 10vw;
  display: inline-block;
  position: relative;
  z-index: 2000;
  width: 70vw;
  p {
    font-size: 28px;
    font-family: "Unica77LLWeb-Regular", helvetica;
  }
  @media (max-width: 850px) {
    margin-left: 0vw;
    margin-top: 20px;
    display: block;
    width: 100%;
    p {
      font-size: 18px;
      font-family: "Unica77LLWeb-Regular", helvetica;
    }
  }
`;
const About = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Poppy Nash</title>
      </Helmet>
      <AboutPageCon>
        <NavCon>
          <ul>
            <LiNavList>
              <Link to="/">Poppy Nash</Link>
            </LiNavList>
            <LiNavList style={{ opacity: 1 }}>
              <Link to="/about">About</Link>
            </LiNavList>
          </ul>
        </NavCon>
        <AboutTextCon>
          <div
            className="about-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicAbout.data.about_text.html,
            }}
          />
        </AboutTextCon>
      </AboutPageCon>
    </>
  );
};
export default withPreview(About);

export const query = graphql`
  {
    prismicAbout {
      data {
        about_text {
          html
        }
      }
    }
  }
`;
